import { useEffect, useRef, useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';

import audioAlarma from '../../assets/audios/alerta.mp3';
import BlockedPng from '../../assets/images/blocked.png';
import ContacSvg from '../../assets/images/contac.svg';
import EnncoSvg from '../../assets/images/ennco.svg';
import ManitaSvg from '../../assets/images/manita.svg';
import TitleComponent from '../../components/TitleComponent';
import useError from '../../hooks/useError';
import { ITrabajador } from '../../models/interfaces/ITrabajador';
import { buscarTrabajadoService } from '../../services/trabajador.service';

import '../../styles/Home.scss';

export default function HomePage() {
	const refInput = useRef(null);
	const [codigoTarjeta, setCodigoTarjeta] = useState<string>('');
	const [trabajador, setTrabajador] = useState<ITrabajador>({
		id_usuario: 0,
		nombre: '',
		status: '',
		tipo: '',
		puesto: '',
		fecha: '',
		entrada: false,
		area: '',
		url_foto: '',
		telefono: '',
		empresa: '',
		amonestaciones: 0,
		tipoAcceso: false,
		id_tarjeta: '',
		result: false,
		resultMessage: '',
	});
	const { setError, ErrorAlert } = useError();

	const consultarInformacionTarjeta = async () => {
		try {
			if (codigoTarjeta) {
				const { data } = await buscarTrabajadoService(+codigoTarjeta);
				setTrabajador(data);
				if (!!data.id_usuario && data.status.toLowerCase() !== 'activo') {
					const alarma = new Audio(audioAlarma);
					alarma.play();
				}
				setCodigoTarjeta('');
			}
		} catch (ex) {
			setError(ex);
		}
	};

	useEffect(() => {
		if (codigoTarjeta.length === 10) {
			consultarInformacionTarjeta();
		}
	}, [codigoTarjeta]);

	return (
		<div className="container mt-5 home">
			<ErrorAlert />
			<TitleComponent titulo="Acceso Personal" />
			<Row className="mt-5">
				<Col xs={12} sm={12} md={12} lg={12}>
					{/** Se coloca el input para la lecutra de las tarjetas */}
					<Form.Group className="w-25">
						<Form.Control
							className="home__codigo"
							ref={refInput}
							name="tarjeta"
							value={codigoTarjeta}
							onInput={(event: any) => {
								setCodigoTarjeta(event.target.value);
							}}
							onBlur={() => {
								setCodigoTarjeta('');
								refInput.current.focus();
							}}
							maxLength={10}
							autoFocus
						/>
					</Form.Group>
					<div className="mt-3">
						<>
							{trabajador.id_tarjeta ? (
								<Card className="mx-auto home__contenedor-tarjeta">
									<Card.Body className="home__tarjeta">
										<div className="home__contenedor-imagen">
											{trabajador.status.toLowerCase() !== 'activo' &&
												trabajador.status.toLowerCase() !== 'inexistente' &&
												!trabajador.status
													.toLowerCase()
													.includes('lectura') && (
													<img
														className="home__manita"
														src={ManitaSvg}
														alt="alerta"
													/>
												)}
											<img
												className={
													trabajador.status.toLowerCase() === 'activo'
														? 'home__foto--entrada'
														: 'home__foto--salida'
												}
												src={
													trabajador.status.toLowerCase() === 'inexistente' ||
													trabajador.status.toLowerCase().includes('lectura')
														? BlockedPng
														: `${process.env.REACT_APP_PATH_AVATAR_MINA}${trabajador.url_foto}`
												}
												alt="Foto Trabajador"
											/>
										</div>
										{trabajador.status.toLowerCase() === 'inexistente' ? (
											<>
												<h2 className="home__estado-inactivo text-center my-4">
													El trabajador no existe.
												</h2>
												<p className="home__item">
													No.Tarjeta: {trabajador.id_tarjeta}
												</p>
											</>
										) : trabajador.status.toLowerCase().includes('lectura') ? (
											<>
												<h2 className="text-center my-4">
													{trabajador.resultMessage}
												</h2>
												<p className="home__item">
													No.Tarjeta: {trabajador.id_tarjeta}
												</p>
											</>
										) : (
											<>
												<p className="text-center home__nombre">
													{trabajador.nombre}
												</p>
												<p className="home__item--es">
													{trabajador.entrada ? 'Entrada' : 'Salida'}
												</p>
												<p className="home__item">
													Estado:{' '}
													<span
														className={
															trabajador.status.toLowerCase() === 'activo'
																? 'home__estado-activo'
																: 'home__estado-inactivo'
														}
													>
														{trabajador.status}
													</span>
												</p>
												<p className="home__item">
													No.Trabajador: {trabajador.id_usuario}
												</p>
												<p className="home__item">
													Empresa: {trabajador.empresa}
												</p>
												<p className="home__item">
													No.Tarjeta: {trabajador.id_tarjeta}
												</p>
											</>
										)}
									</Card.Body>
								</Card>
							) : (
								<>
									<div className="home__logotipos">
										<img
											className="home__logotipo"
											src={ContacSvg}
											width="35%"
											alt="Contac"
										/>
										<img
											className="home__logotipo"
											src={EnncoSvg}
											width="35%"
											alt="Ennco"
										/>
									</div>
									<p className="home__texto-logotipos">
										Para mostrar los trabajadores, pase la tarjeta por la
										lectora.
									</p>
								</>
							)}
						</>
					</div>
				</Col>
			</Row>
		</div>
	);
}
